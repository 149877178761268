<script>
export default {
    components: {
        Header: () => import('components/base/Header'),

        'mt-cell':app.Mint.Cell,
        'mt-button':app.Mint.Button,
        'mt-header':app.Mint.Header,
    },
    data() {
        return {
            logourl:null,
            mainTitle:window.localStorage.getItem("maintitle"),
            header:{
                mainTitle3: getWord('about_us2')
            }
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        }
    },
    mounted() {
        this.logourl=window.localStorage.getItem('logourl')&&encodeURI(localStorage.getItem('logourl'));
    },
};
</script>
<template>
    <div id="about" class="main" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
            <div class="background_color"></div>
            <Header 
                :mainTitle="header.mainTitle3" 
                :backButton=false 
            />
            <div class="main_image">
                <img :src="logourl" alt class="image">
            </div>
            <span class="span_text">V1.0.0</span>
            <div class="main_text2">                
                <router-link to="/my/about/about">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('help2')" to is-link value></mt-cell>
                </router-link>                   
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header 
                :mainTitle="header.mainTitle3" 
                :backButton=false 
            />
            <div class="inner">
                <h2>
                    <img :src="logourl" />
                    <b>{{mainTitle}}</b>
                    <!-- <span>V1.0.0</span> -->
                </h2>
                <ul>
                    <li>
                        <router-link to="/my/about/about">
                            {{ getWord('help2') }}
                            <i class="iconfont icon-back"></i>
                        </router-link>                        
                    </li>
                </ul>
            </div>
            
        </template>
    </div>
</template>
<style lang='scss' type="text/css" scoped>
#about {

    &.template-3 {

        .inner {
            padding: .25rem 0;
        }

        h2 {
            text-align: center;

            img {
                max-height: 1.84rem;
                max-width: 1.84rem;
            }

            b {
                display: block;
                font-size: .36rem;
                font-weight: normal;
            }

            span {
                display: block;
                font-size: .3rem;
            }
        }

        ul {
            margin-top: 1rem;
            padding: 0 .5rem;
            list-style: none;

            li {                
                padding: 0 .25rem;
                border-bottom:1px solid #B0B0B0;

                &:first-child {
                    border-top:1px solid #b0b0b0;
                }

                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: .25rem 0;
                    font-size: .3rem;
                    color: #5F646E;

                    i {
                        transform: rotateZ(180deg);
                        color: #C8C8CD;
                    }
                }

                
            }
        }
    }
}
.background_color {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.main_image {
    float: left;
    width: 100%;
    height: 1.84rem;
    margin-top: 0.4rem;
    text-align: center;
}

.image {
    max-height: 2rem;
    max-width: 4rem;
    border-radius: 1rem;
}

.span_text {
    float: left;
    width: 7.5rem;
    height: 0.3rem;
    font-size: 0.3rem;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    text-align: center;
}

.main_text2 {
    float: left;
    width: 7.49rem;
    border-radius: 0.1rem;
    margin-top: 0.5rem;
    background-color: white;
    box-shadow: 0 0 0.05rem #ccc;
}
</style>